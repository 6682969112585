import * as React from 'react';
import { FC, useEffect } from 'react';
import * as firebase from 'firebase/app';
import * as Auth from 'firebase/auth';
import { navigate, PageProps } from 'gatsby';
import { verifyEmailByActionCode } from '../utils/firebaseActions';

const ActionPage: FC<PageProps> = ({ location }) => {
  const params = new URLSearchParams(location.search);
  const mode = params.get('mode');
  const oobCode = params.get('oobCode');
  useEffect(() => {
    const auth = Auth.getAuth(firebase.getApp());
    if (mode === 'verifyEmail' || mode === 'verifyAndChangeEmail') {
      verifyEmailByActionCode(auth, oobCode)
        .then(() => {
          navigate('/emailVerified/', { replace: true });
        })
        .catch((e) => {
          console.warn(e);
          navigate('/emailVerificationError/', { replace: true });
        });
    } else if (mode === 'resetPassword') {
      console.log(location.search);
      const resetPasswordParams = new URLSearchParams(params);
      resetPasswordParams.delete('mode');
      navigate(`/resetPassword/?${resetPasswordParams.toString()}`, {
        replace: true,
      });
    } else {
      navigate('/');
    }
  }, []);
  return null;
};

export default ActionPage;
